import { Stack, Typography } from "@mui/material";


export const Unauthorized = () =>{



    return (<Stack
        direction="column"
        justifyContent="center"
        alignItems="stretch"
        spacing={1}
      >
          <Typography variant="h5">Unauthorized Access</Typography>

        
      </Stack>);
}