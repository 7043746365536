// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

import {
    GoogleAuthProvider,
    getAuth,
    signInWithPopup,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signOut,
  } from "firebase/auth";
  import {
    getFirestore,
    doc,
    setDoc,
    getDocs,
  } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC7fluAvnwOZEhVmgzL5UoFYJaXWMobTAw",
  authDomain: "westco-fuel.firebaseapp.com",
  projectId: "westco-fuel",
  storageBucket: "westco-fuel.appspot.com",
  messagingSenderId: "586375427401",
  appId: "1:586375427401:web:17f25d648a2427f141d698"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const googleProvider = new GoogleAuthProvider();

const signInWithGoogle = async () => {
  try {
    
    const res = await signInWithPopup(auth, googleProvider);
  } catch (err) {
    //console.error(err);
    //alert(err.message);
  }
};

const signUpWithGoogle = async () => {
  try {
    
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    //console.log(user.uid);
    await setDoc(doc(db, "users", user.uid),{
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
        role: "Unassigned",
        active: true,
    }, { merge: true });
  } catch (err) {
    //console.error(err);
    //alert(err.message);
  }
};

const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const registerWithEmailAndPassword = async (name, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;

    await setDoc(doc(db, "users", user.uid),{
        uid: user.uid,
        name,
        authProvider: "local",
        email,
        role: "Unassigned",
        active: true,
    }, { merge: true });
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  signOut(auth);
};


const fetchDocDictionary = async (q) => {

  const qs = await getDocs(q);

  const dict = {};

  qs.forEach((doc) => {
    dict[doc.id] = doc.data();
  });

  return dict;
};

export {
  auth,
  db,
  signInWithGoogle,
  signUpWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
  fetchDocDictionary,
};
