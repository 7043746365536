import Select from "@mui/material/Select";

export const CustomizedSelect = ({ children, form, field, connected, resetvalue }) => {
  const { name, value, label } = field;
  const { setFieldValue } = form;

  return (
      <Select
        fullWidth
        labelId="simple-select-label"
        name={name}
        label={label}
        value={value}
        onChange={(e) => {
          setFieldValue(name, e.target.value);
          if (connected) setFieldValue(connected, resetvalue);
        }}
      >
        {children}
      </Select>
  );
};
