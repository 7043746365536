import axios from "axios";

const prod = "https://westco.fly.dev/api";
const dev = "http://localhost:8080/api";

export default axios.create({
  baseURL: prod,
  headers: {
    "Content-type": "application/json"
  }
});