import http from "./http-common";

class TransactionDataService {
    getAll() {
      return http.get("/transactions");
    }

    getForLocation(locationId, begin, end) {
      return http.get(`/transactions?begin=${begin}&end=${end}&locationId=${locationId}`);
    }
    getForDates(begin, end) {
      return http.get(`/transactions?begin=${begin}&end=${end}`);
    }
    getTransfers(locationId, begin, end) {
      return http.get(`/transactions/transfers?begin=${begin}&end=${end}&locationId=${locationId}`);
    }
    
    get(id) {
      return http.get(`/transactions/${id}`);
    }
  
    create(data) {
        return http.post("/transactions", data);
    }

    delete(id) {
      return http.delete(`/transactions/${id}`);
    }
}

export default new TransactionDataService();