import { Stack, Typography } from "@mui/material";


export const Unactive = () =>{



    return (<Stack
        direction="column"
        justifyContent="center"
        alignItems="stretch"
        spacing={1}
      >
          <Typography variant="h5">Access Deactivated</Typography>

        
      </Stack>);
}