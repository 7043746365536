import PropTypes from "prop-types";
import Button from "@mui/material/Button";

import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import AddIcon from "@mui/icons-material/Add";

import { GridToolbarContainer } from "@mui/x-data-grid";

export const EditToolbar = (props) => {
    const { setActive, handleNew, label } = props;
  
    const handleChange = (event) => {
      setActive(event.target.checked);
    };
  
    return (
      <GridToolbarContainer
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button color="primary" startIcon={<AddIcon />} onClick={handleNew}>
          Add New {label}
        </Button>
  
        <FormControlLabel
          value="active"
          control={<Switch color="primary" defaultChecked />}
          label="Active"
          labelPlacement="start"
          onChange={handleChange}
        />
      </GridToolbarContainer>
    );
  }
  
  EditToolbar.propTypes = {
    setActive: PropTypes.func.isRequired,
    handleNew: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
  };