import * as React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

export const Copyright = (props) => {
  return (
    <Box display={"flex"} sx={{ justifyContent: "space-between" }}>
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        {...props}
      >
        {"© "}
        {new Date().getFullYear()}{" "}
        <Link color="inherit" href="https://cehm-systems.com/" target="blank">
          CEHM Systems
        </Link>
      </Typography>
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        {...props}
      >
        {"© "}
        {new Date().getFullYear()}{" "}
        <Link color="inherit" href="https://westco.coop/" target="blank">
          WESTCO
        </Link>
      </Typography>
    </Box>
  );
};
