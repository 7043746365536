import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  collection,
  query,
  where,
  getDocs,
  orderBy,
  Timestamp,
  serverTimestamp,
  doc,
  setDoc
} from "firebase/firestore";
import { db } from "../../services/firebase";
import { UserAuth } from "../../services/AuthContext";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import BackspaceTwoToneIcon from "@mui/icons-material/BackspaceTwoTone";
import Typography from "@mui/material/Typography";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";

import { EditToolbar } from "../../common/edittoolbar";

import {
  DataGrid,
  GridActionsCellItem,
  GridRowModes,
} from "@mui/x-data-grid";

export const EditProducts = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(true);
  const [rowModesModel, setRowModesModel] = useState({});
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const navigate = useNavigate();
  const { user } = UserAuth();

  useEffect(() => {
    const fetchData = async () => {
      const q = query(
        collection(db, "products"),
        where("active", "==", active),
        orderBy("name")
      );
      const qs = await getDocs(q);

      const p = [];

      qs.forEach((doc) => {
        p.push({
          ...doc.data(),
          id: doc.id,
        });
      });

      setLoading(true);
      setProducts(p);
      setLoading(false);
    };

    fetchData();
  }, [active]);

  const columns = [
    { field: "name", headerName: "Product Name", flex: 1, editable: true },
    {
      field: "macro",
      headerName: "Product Type",
      flex: 1,
      editable: true,
      type: "singleSelect",
      valueOptions: ["Gas", "Diesel"],
    },
    {
      field: "active",
      headerName: "Active",
      flex: 1,
      type: "boolean",
      editable: true,
    },
    // {
    //   field: "lastUpdated",
    //   headerName: "Last Updated",
    //   flex: 1,
    //   type: "dateTime",
    //   valueGetter: ({ value }) => value && value.toDate(),
    //   renderCell: (params) =>
    //     moment(params.row.lastUpdated.toDate()).format("M/D/YY, h:mm:ss a"),
    // },
    // { field: "lastUpdatedBy", headerName: "Last Updated By", flex: 1 },
    // { field: 'id', headerName: 'Id', flex: 1 },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        //console.log(rowModesModel);
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  // https://codesandbox.io/s/0o2gny?file=/demo.js:2661-3076
  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => async () => {
    //setRows(rows.filter((row) => row.id !== id));
    setLoading(true);
    //const done = await new Promise((res) => setTimeout(res, 2500));
    const deleted = {
      active: false,
      lastUpdatedBy: user.uid,
      lastUpdated: serverTimestamp()
    }

    const docRef = doc(db, 'products', id);
    await setDoc(docRef, deleted, {merge: true});
    setLoading(false);
    //setProducts(products.filter((product) => product.id !== id));
    //setActive(false);
    setProducts(products.map(p => {
      if (p.id === id){
        return {...p, active: false};
      }
      return p;
    }));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const handleNew = async () => {

    setLoading(true);
    //const done = await new Promise((res) => setTimeout(res, 2500));
    const newRef = doc(collection(db, "products"));
    console.log(newRef.id);
    const id = newRef.id;
    setLoading(false);

    setProducts((oldRows) => [
      ...oldRows,
      {
        id,
        name: "",
        macro: "Gas",
        active: true,
        lastUpdated: new Timestamp(),
        isNew: true,
      },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "name" },
    }));
  };

  const processRowUpdate = async (newRow) => {

    const updatedRow = { ...newRow, isNew: false };
    //setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));

    setLoading(true);
    //const done = await new Promise((res) => setTimeout(res, 2500));

    newRow.lastUpdatedBy = user.uid;
    newRow.lastUpdated = serverTimestamp();

    const docRef = doc(db, 'products', newRow.id);
    await setDoc(docRef, newRow, {merge: true});

    setLoading(false);

    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="stretch"
      spacing={1}
    >
      <Box display="flex" justifyContent="space-between" p={2}>
        <Typography variant="h5">Manage Products</Typography>

        <Box display="flex">
          <Button
            variant="text"
            onClick={() => navigate('/maintenance')}
            startIcon={<BackspaceTwoToneIcon />}
          >
            Back
          </Button>
        </Box>
      </Box>
      <DataGrid
        loading={loading}
        autoHeight
        density="compact"
        pageSizeOptions={[5, 10, 25]}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        columns={columns}
        rows={products}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slots={{
          toolbar: EditToolbar,
        }}
        slotProps={{
          toolbar: { setActive, handleNew, label: "Product" },
        }}
      />
    </Stack>
  );
};


