import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { useCollectionOnce } from "react-firebase-hooks/firestore";
import {
  collection,
  query,
  where,
  orderBy,
  serverTimestamp,
} from "firebase/firestore";
import { db, auth } from "../../services/firebase";
import TransactionDataService from "../../services/transactions.service";
import { v4 as uuidv4 } from 'uuid';

import { Formik, Form, Field, ErrorMessage } from "formik";
import { TextField } from "formik-mui";
import { array, number, object, string, bool, when, test } from "yup";
import { CustomizedSelect } from "../../common/select";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import BackspaceTwoToneIcon from "@mui/icons-material/BackspaceTwoTone";
import SaveIcon from "@mui/icons-material/Save";
import Typography from "@mui/material/Typography";

import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import Skeleton from "@mui/material/Skeleton";

import { enqueueSnackbar } from 'notistack';

import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";

export const SurveyTransfers = () => {
  const [reportingDate, setReportingDate] = useState(dayjs());
  const formikRef = useRef();
  const navigate = useNavigate();

  const [locations, loadingLocations, errorLocations] = useCollectionOnce(
    query(
      collection(db, "locations"),
      where("active", "==", true),
      orderBy("name")
    )
  );

  const [products, loadingProducts, errorProducts] = useCollectionOnce(
    query(
      collection(db, "products"),
      where("active", "==", true),
      orderBy("name")
    )
  );

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        product: "",
        gallons: 0,
        locationFrom: "",
        locationTo: "",
      }}
      validationSchema={object({
        product: string().required("Please select a product"),
        gallons: number().integer("Please enter gallons in whole numbers").moreThan(0, "You must enter greater than zero (0) gallons"),
        locationFrom: string().required("Please select a location"),
        locationTo: string().required("Please select a location"),
      })}
      //onChange={e => console.log(e)}
      onSubmit={async (values, helpers) => {
        //console.log("sumbitted", values);
        const entryDate = reportingDate.startOf("day").toDate();
        const lastUpdated = serverTimestamp();

          const uuid = uuidv4();

          await TransactionDataService.create({
            reportingDate: entryDate,
            type: "out",
            locationId: values.locationFrom,
            transfer: uuid,
            productId: values.product,
            gallons: values.gallons,
            lastUpdated: lastUpdated,
            lastUpdatedBy: auth.currentUser.uid,
          });

          await TransactionDataService.create({
            reportingDate: entryDate,
            type: "in",
            locationId: values.locationTo,
            transfer: uuid,
            productId: values.product,
            gallons: values.gallons,
            lastUpdated: lastUpdated,
            lastUpdatedBy: auth.currentUser.uid,
          });

        helpers.resetForm();
        enqueueSnackbar('Transfer Transactions Recorded');
        navigate('/survey');
      }}
    >
      {({ values, errors, isSubmitting, handleChange }) => (
        <Form>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            spacing={1}
          >
            <Box display="flex" justifyContent="space-between" p={2}>
              <Typography variant="h5">Transfer Reporting</Typography>

              <Box display="flex">
                <Button
                  variant="text"
                  onClick={() => navigate('/survey')}
                  startIcon={<BackspaceTwoToneIcon />}
                >
                  Back
                </Button>
              </Box>
            </Box>
            {errorLocations && (
              <Grid item xs={12}>
                <strong>Error: {JSON.stringify(errorLocations)}</strong>
              </Grid>
            )}
            {loadingLocations && (
              <Grid item xs={12}>
                <Skeleton />
              </Grid>
            )}
            {locations && (
                <>
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={12}
                  md={4}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography variant="h6">
                    Transfer From Location
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Field
                    fullWidth
                    component={CustomizedSelect}
                    label="Select Location"
                    name={`locationFrom`}
                  >
                    <MenuItem value="" name="Location Not Selected" selected>
                      Location Not Selected
                    </MenuItem>
                    {locations.docs.map((doc) => (
                      <MenuItem
                        key={doc.id}
                        value={doc.id}
                        name={doc.data().name}
                      >
                        {doc.data().name}
                      </MenuItem>
                    ))}
                  </Field>
                  <Box display="flex" justifyContent="left">
                    <ErrorMessage
                      name="location"
                      component="div"
                      className="cehm-error-sm"
                    ></ErrorMessage>
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
              <Grid
                item
                xs={12}
                md={4}
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="h6">
                  Transfer To Location
                </Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                <Field
                  fullWidth
                  component={CustomizedSelect}
                  label="Select Location"
                  name={`locationTo`}
                >
                  <MenuItem value="" name="Location Not Selected" selected>
                    Location Not Selected
                  </MenuItem>
                  {locations.docs.map((doc) => (
                    <MenuItem
                      key={doc.id}
                      value={doc.id}
                      name={doc.data().name}
                    >
                      {doc.data().name}
                    </MenuItem>
                  ))}
                </Field>
                <Box display="flex" justifyContent="left">
                  <ErrorMessage
                    name="location"
                    component="div"
                    className="cehm-error-sm"
                  ></ErrorMessage>
                </Box>
              </Grid>
            </Grid>
            </>
            )}
            {errorProducts && (
              <Grid item xs={12}>
                <strong>Error: {JSON.stringify(errorProducts)}</strong>
              </Grid>
            )}
            {loadingProducts && (
              <Grid item xs={12}>
                <Skeleton />
              </Grid>
            )}
            {products && (
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={12}
                  md={4}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography variant="h6">
                    What product did you sell today?
                  </Typography>
                </Grid>
                <Grid item xs={6} md>
                  <Field
                    fullWidth
                    component={CustomizedSelect}
                    products={products}
                    label="Select Product"
                    name={`product`}
                    connected={`gallons`}
                    resetvalue={0}
                  >
                    {products.docs.map((doc) => (
                      <MenuItem
                        key={doc.id}
                        value={doc.id}
                        name={doc.data().name}
                      >
                        {doc.data().name}
                      </MenuItem>
                    ))}
                  </Field>
                  <Box display="flex" justifyContent="left">
                    <ErrorMessage
                      name="product"
                      component="div"
                      className="cehm-error-sm"
                    ></ErrorMessage>
                  </Box>
                </Grid>

                <Grid item xs={6} md>
                  <Field
                    fullWidth
                    name={`gallons`}
                    component={TextField}
                    label="Gallons"
                    type="number"
                    variant="outlined"
                  ></Field>
                </Grid>
              </Grid>
            )}
            <Box display="flex" justifyContent="right" p={2}>
              <Box display="flex" pr={2}>
                <DatePicker
                  name="reportingDate"
                  label="Reporting Date"
                  value={reportingDate}
                  onChange={(newValue) => setReportingDate(newValue)}
                ></DatePicker>
              </Box>
              <Box display="flex">
                <Button
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                  startIcon={
                    isSubmitting ? (
                      <CircularProgress size="0.9rem" />
                    ) : (
                      <SaveIcon />
                    )
                  }
                >
                  {isSubmitting ? "Submitting" : "Submit"}
                </Button>
              </Box>
            </Box>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
