import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { useCollectionOnce } from "react-firebase-hooks/firestore";
import {
  collection,
  query,
  where,
  getDocs,
  orderBy,
  updateDoc,
  doc,
  Timestamp,
} from "firebase/firestore";
import { db, auth } from "../../services/firebase";
import InventoryDataService from "../../services/inventories.service";

import { Formik, Form, Field, FieldArray } from "formik";
import { array, number, object } from "yup";
import { TextField } from "formik-mui";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import BackspaceTwoToneIcon from "@mui/icons-material/BackspaceTwoTone";
import SaveIcon from "@mui/icons-material/Save";
import Typography from "@mui/material/Typography";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import Skeleton from "@mui/material/Skeleton";

import { enqueueSnackbar } from "notistack";

import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";

export const SurveyInventory = () => {
  const [locationId, setLocation] = useState("");
  const [tanks, setTanks] = useState();
  const [tanksLoading, setTankLoading] = useState(false);
  const navigate = useNavigate();

  const [locations, loading, error] = useCollectionOnce(
    query(
      collection(db, "locations"),
      where("active", "==", true),
      orderBy("name")
    )
  );

  useEffect(() => {
    const fetchData = async () => {
      if (locationId !== "") {
        const q = query(
          collection(db, "locations", locationId, "tanks"),
          where("active", "==", true),
          orderBy("tankNumber")
        );
        const qs = await getDocs(q);

        const t = [];
        qs.forEach((doc) => {
          t.push({
            ...doc.data(),
            id: doc.id,
            feet: doc.data().latestReadingFeet ?? 0,
            inches: doc.data().latestReadingInches ?? 0,
            gallons: doc.data().latestReadingGallons ?? 0,
          });
        });

        setTankLoading(true);
        setTanks(t);
        console.log(t);
        setTankLoading(false);
      }
    };

    fetchData();
  }, [locationId]);

  const selectLocation = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setLocation(event.target.value);
  };

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="stretch"
      spacing={1}
    >
      <Box display="flex" justifyContent="space-between" p={2}>
        <Typography variant="h5">Tank Inventory Reporting</Typography>

        <Box display="flex">
          <Button
            variant="text"
            onClick={() => navigate("/survey")}
            startIcon={<BackspaceTwoToneIcon />}
          >
            Back
          </Button>
        </Box>
      </Box>
      {error && <strong>Error: {JSON.stringify(error)}</strong>}
      {loading && <CircularProgress></CircularProgress>}
      {locations && (
        <FormControl fullWidth>
          <InputLabel
            id="location-select-label"
            style={{ backgroundColor: "white" }}
          >
            Select Location
          </InputLabel>
          <Select
            labelId="location-select-label"
            id="location-select"
            label="Location"
            value={locationId}
            onChange={(event) => {
              selectLocation(event);
            }}
          >
            {locations.docs.map((doc) => (
              <MenuItem key={doc.id} value={doc.id} name={doc.data().name}>
                {doc.data().name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {tanksLoading && <Skeleton fullWidth height="220px"></Skeleton>}
      {tanks && <SurveyInventoryForm locationId={locationId} tanks={tanks} />}
    </Stack>
  );
};

const SurveyInventoryForm = (props) => {
  const [reportingDate, setReportingDate] = useState(dayjs());
  const formikRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setFieldValue("tanks", props.tanks);
    }
  }, [props.tanks]);

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{ tanks: props.tanks }}
      validationSchema={object({
        tanks: array(
          object({
            gallons: number()
              .positive()
              .min(0, "Please enter at least 0 gallons"),
            feet: number()
              .positive()
              .integer("Please enter feet in whole numbers")
              .min(0, "Please enter at least 0 feet")
              .max(32, "Maximum allowed value is 32 feet"),
            inches: number()
              .positive()
              .min(0, "Please enter at least 0 inches")
              .max(11.5, "Maximum allowed value is 11.5 inches")
              .test(
                "validHalf",
                "Measurement must be in 1/2 inches",
                (value) => (value * 2) % 1 === 0
              ),
          })
        ),
      })}
      onSubmit={async (values) => {
        try {
          const entryDate = reportingDate.startOf("day").toDate();

          for (const tank of values.tanks) {
            if (tank.entry !== "gallons") {
              tank.gallons =
                tank.gallonsPerInch * (tank.feet * 12 + tank.inches);
            }

            const data = {
              reportingDate: entryDate,
              locationId: tank.locationId,
              tankId: tank.id,
              tankNumber: tank.tankNumber,
              productId: tank.productId,
              gallons: tank.gallons,
              capacity: tank.capacity,
              percentOfCapacity: tank.gallons / tank.capacity,
              lastUpdatedBy: auth.currentUser.uid,
            };

            await InventoryDataService.create(data);

            if (
              !tank.lastReading ||
              tank.lastReading <= Timestamp.fromDate(entryDate)
            ) {
              await updateDoc(
                doc(db, "locations", tank.locationId, "tanks", tank.id),
                {
                  lastReading: Timestamp.fromDate(entryDate),
                  latestReadingFeet: tank.feet,
                  latestReadingInches: tank.inches,
                  latestReadingGallons: tank.gallons,
                }
              );
            }
          }

          enqueueSnackbar("Tank Inventory Recorded");
          //return new Promise((res) => setTimeout(res, 2500));
          navigate("/survey");
        } catch (error) {
          enqueueSnackbar(error);
        }
      }}
    >
      {({ values, errors, isSubmitting }) => (
        <Form>
          <Stack spacing={0.5}>
            <Typography variant="h5" textAlign={"center"}>
              Enter Tank Ending Inventory
            </Typography>
            <FieldArray name="tanks">
              {() => (
                <>
                  {values.tanks.map((tank, index) => (
                    <Grid container key={index} spacing={1}>
                      <Grid
                        item
                        xs={12}
                        sm
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Typography variant="h6">{tank.name}</Typography>
                      </Grid>
                      {(!tank.entry || tank.entry !== "gallons") && (
                        <>
                          <Grid item xs={6} sm>
                            <Field
                              fullWidth
                              name={`tanks[${index}].feet`}
                              component={TextField}
                              label="Feet"
                              type="number"
                              variant="outlined"
                              inputProps={{ max: 32, min: 0 }}
                            ></Field>
                          </Grid>
                          <Grid item xs={6} sm>
                            <Field
                              fullWidth
                              name={`tanks[${index}].inches`}
                              component={TextField}
                              label="Inches"
                              type="number"
                              variant="outlined"
                              inputProps={{ step: 0.5, max: 11.5, min: 0 }}
                            ></Field>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Typography
                              variant="body1"
                              paddingTop={2}
                              align="center"
                            >{`${(
                              tank.gallonsPerInch *
                              (tank.feet * 12 + tank.inches)
                            ).toLocaleString()} gallons`}</Typography>
                          </Grid>
                        </>
                      )}
                      {tank.entry && tank.entry === "gallons" && (
                        <>
                          <Grid item xs={12} sm={9}>
                            <Field
                              fullWidth
                              name={`tanks[${index}].gallons`}
                              component={TextField}
                              label="Gallons"
                              type="number"
                              variant="outlined"
                              inputProps={{ min: 0 }}
                            ></Field>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  ))}
                </>
              )}
            </FieldArray>
            <Box display="flex" justifyContent="right" p={2}>
              <Box display="flex" pr={2}>
                <DatePicker
                  name="reportingDate"
                  label="Reporting Date"
                  value={reportingDate}
                  onChange={(newValue) => setReportingDate(newValue)}
                ></DatePicker>
              </Box>
              <Box display="flex">
                <Button
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                  startIcon={
                    isSubmitting ? (
                      <CircularProgress size="0.9rem" />
                    ) : (
                      <SaveIcon />
                    )
                  }
                >
                  {isSubmitting ? "Submitting" : "Submit"}
                </Button>
              </Box>
            </Box>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
