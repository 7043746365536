import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { useCollectionOnce } from "react-firebase-hooks/firestore";
import { collection, query, where, orderBy } from "firebase/firestore";
import { db, fetchDocDictionary } from "../../services/firebase";
import InventoryDataService from "../../services/inventories.service";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import BackspaceTwoToneIcon from "@mui/icons-material/BackspaceTwoTone";
import RefreshIcon from "@mui/icons-material/Refresh";
import Typography from "@mui/material/Typography";

import { Formik, Form, Field } from "formik";
import { CustomizedSelect } from "../../common/select";

import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import Skeleton from "@mui/material/Skeleton";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";

import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import moment from "moment";

export const ReportShrinks = () => {
  const [dateBegin, setDateBegin] = useState(dayjs());
  const [dateEnd, setDateEnd] = useState(dayjs());
  const formikRef = useRef();
  const navigate = useNavigate();

  const [tableLoading, setTableLoading] = useState(false);
  const [productCapacities, setProductCapacities] = useState([]);
  const [macroCapacities, setMacroCapacities] = useState([]);

  const [products, setProducts] = useState();

  const [valueTab, setValueTab] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  const [locations, locationsLoading, locationsError] = useCollectionOnce(
    query(
      collection(db, "locations"),
      where("active", "==", true),
      orderBy("name")
    )
  );

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setFieldValue("dateBegin", dateBegin);
    }
  }, [dateBegin]);

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setFieldValue("dateEnd", dateEnd);
    }
  }, [dateEnd]);

  const productColumns = [
    {
      field: "location",
      headerName: "Location",
      flex: 1.5,
      hidden: true,
      editable: false,
    },
    { field: "productName", headerName: "Product", flex: 2, editable: false },
    {
      field: "beginInventory",
      headerName: "Beginning",
      flex: 1,
      headerAlign: "left",
      align: "left",
      type: "number",
      editable: false,
    },
    {
      field: "purchases",
      headerName: "Purchases",
      flex: 1,
      headerAlign: "left",
      align: "left",
      type: "number",
      editable: false,
    },
    {
      field: "transfersIn",
      headerName: "Transfers In",
      flex: 1,
      headerAlign: "left",
      align: "left",
      type: "number",
      editable: false,
    },
    {
      field: "transfersOut",
      headerName: "Transfers Out",
      flex: 1,
      headerAlign: "left",
      align: "left",
      type: "number",
      editable: false,
    },
    {
      field: "sales",
      headerName: "Sales",
      flex: 1,
      headerAlign: "left",
      align: "left",
      type: "number",
      editable: false,
    },
    {
      field: "endInventory",
      headerName: "Ending Book",
      flex: 1,
      headerAlign: "left",
      align: "left",
      type: "number",
      editable: false,
    },
    {
      field: "actual",
      headerName: "Actual",
      flex: 1,
      headerAlign: "left",
      align: "left",
      type: "number",
      editable: false,
    },
    {
      field: "gain",
      headerName: "Gain/Shrink",
      flex: 1,
      headerAlign: "left",
      align: "left",
      type: "number",
      editable: false,
    },
    {
      field: "beginDate",
      headerName: "Begin Inventory Date",
      flex: 1,
      type: "dateTime",
      hidden: true,
      editable: false,
      valueGetter: ({ value }) => value && value.toDate(),
      renderCell: (params) =>
        moment(params.row.beginDate.toDate()).format("M/D/YY"),
    },
    {
      field: "reportingDate",
      headerName: "Reporting Date",
      flex: 1,
      type: "dateTime",
      editable: false,
      valueGetter: ({ value }) => value && value.toDate(),
      renderCell: (params) =>
        moment(params.row.reportingDate.toDate()).format("M/D/YY"),
    },
  ];

  const macroColumns = [
    {
      field: "location",
      headerName: "Location",
      flex: 1.5,
      hidden: true,
      editable: false,
    },
    { field: "productMacro", headerName: "Macro", flex: 2, editable: false },
    {
      field: "beginInventory",
      headerName: "Beginning",
      flex: 1,
      headerAlign: "left",
      align: "left",
      type: "number",
      editable: false,
    },
    {
      field: "purchases",
      headerName: "Purchases",
      flex: 1,
      headerAlign: "left",
      align: "left",
      type: "number",
      editable: false,
    },
    {
      field: "transfersIn",
      headerName: "Transfers In",
      flex: 1,
      headerAlign: "left",
      align: "left",
      type: "number",
      editable: false,
    },
    {
      field: "transfersOut",
      headerName: "Transfers Out",
      flex: 1,
      headerAlign: "left",
      align: "left",
      type: "number",
      editable: false,
    },
    {
      field: "sales",
      headerName: "Sales",
      flex: 1,
      headerAlign: "left",
      align: "left",
      type: "number",
      editable: false,
    },
    {
      field: "endInventory",
      headerName: "Ending Book",
      flex: 1,
      headerAlign: "left",
      align: "left",
      type: "number",
      editable: false,
    },
    {
      field: "actual",
      headerName: "Actual",
      flex: 1,
      headerAlign: "left",
      align: "left",
      type: "number",
      editable: false,
    },
    {
      field: "gain",
      headerName: "Gain/Shrink",
      flex: 1,
      headerAlign: "left",
      align: "left",
      type: "number",
      editable: false,
    },
    {
      field: "beginDate",
      headerName: "Last Inventory Date",
      flex: 1,
      type: "dateTime",
      hidden: true,
      editable: false,
      valueGetter: ({ value }) => value && value.toDate(),
      renderCell: (params) =>
        moment(params.row.beginDate.toDate()).format("M/D/YY"),
    },
    {
      field: "reportingDate",
      headerName: "Reporting Date",
      flex: 1,
      type: "dateTime",
      hidden: true,
      editable: false,
      valueGetter: ({ value }) => value && value.toDate(),
      renderCell: (params) =>
        moment(params.row.reportingDate.toDate()).format("M/D/YY"),
    },
  ];

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="stretch"
      spacing={1}
    >
      <Box display="flex" justifyContent="space-between" p={2}>
        <Typography variant="h5">Gain/Shrink</Typography>

        <Box display="flex">
          <Button
            variant="text"
            onClick={() => navigate("/dashboard")}
            startIcon={<BackspaceTwoToneIcon />}
          >
            Back
          </Button>
        </Box>
      </Box>
      <Formik
        innerRef={formikRef}
        initialValues={{ locationId: "", dateBegin: dayjs(), dateEnd: dayjs() }}
        onSubmit={async (values, helpers) => {
          //console.log("sumbitted", values);

          setTableLoading(true);

          let productsDictionary = products;

          if (!productsDictionary) {
            const dict = await fetchDocDictionary(
              query(collection(db, "products"))
            );
            setProducts(dict);
            productsDictionary = dict;
            //console.log("products", productsDictionary);
          }

          const response = await InventoryDataService.getShrinkForLocation(
            values.locationId,
            dateBegin.startOf("day").toDate(),
            dateEnd.startOf("day").toDate()
          );

          const p = {};
          const m = {};

          Object.values(response.data).forEach((shrink) => {
            let dateKey = dayjs(shrink.actualDate).format("YYYYMMDD");
            let key = `${shrink.locationId}-${shrink.productId}-${dateKey}`;

            const location = locations.docs.find(
              (l) => l.id === shrink.locationId
            );

            if (!p[key]) {
              p[key] = {
                id: key,
                location: location?.data()?.name,
                productName: productsDictionary[shrink.productId].name,
                productMacro: productsDictionary[shrink.productId].macro,
                beginInventory: shrink.beginInventory,
                purchases: shrink.purchases,
                transfersIn: shrink.transfersIn,
                transfersOut: shrink.transfersOut,
                sales: shrink.sales,
                endInventory: shrink.endInventory,
                actual: shrink.actual,
                gain: shrink.gain,
                beginDate: dayjs(shrink.beginDate),
                reportingDate: dayjs(shrink.actualDate),
              };
            }

            key = `${shrink.locationId}-${
              productsDictionary[shrink.productId].macro
            }-${dateKey}`;
            if (!m[key]) {
              m[key] = {
                id: key,
                location: location?.data()?.name,
                productMacro: productsDictionary[shrink.productId].macro,
                beginInventory: 0,
                purchases: 0,
                transfersIn: 0,
                transfersOut: 0,
                sales: 0,
                endInventory: 0,
                actual: 0,
                gain: 0,
                beginDate: dayjs(shrink.beginDate),
                reportingDate: dayjs(shrink.actualDate),
              };
            }

            m[key] = {
              id: key,
              location: location?.data()?.name,
              productMacro: productsDictionary[shrink.productId].macro,
              beginInventory: m[key].beginInventory + shrink.beginInventory,
              purchases: m[key].purchases + shrink.purchases,
              transfersIn: m[key].transfersIn + shrink.transfersIn,
              transfersOut: m[key].transfersOut + shrink.transfersOut,
              sales: m[key].sales + shrink.sales,
              endInventory: m[key].endInventory + shrink.endInventory,
              actual: m[key].actual + shrink.actual,
              gain: m[key].gain + shrink.gain,
              beginDate:
                m[key].beginDate > dayjs(shrink.beginDate)
                  ? dayjs(shrink.beginDate)
                  : m[key].beginDate,
              reportingDate:
                m[key].actualDate < dayjs(shrink.actualDate)
                  ? dayjs(shrink.actualDate)
                  : m[key].actualDate,
            };
          });

          setProductCapacities(
            Object.values(p).map((element) => ({
              ...element,
            }))
          );
          setMacroCapacities(
            Object.values(m).map((element) => ({
              ...element,
            }))
          );

          //const done = await new Promise((res) => setTimeout(res, 2500));
          setTableLoading(false);
        }}
      >
        {({ values, errors, isSubmitting }) => (
          <Form>
            {locationsError && (
              <Grid item xs={12}>
                <strong>Error: {JSON.stringify(locationsError)}</strong>
              </Grid>
            )}
            {locationsLoading && (
              <Grid item xs={12}>
                <Skeleton />
              </Grid>
            )}
            {locations && (
              <Grid container spacing={1}>
                <Grid item xs={12} md={4}>
                  <Field
                    fullWidth
                    component={CustomizedSelect}
                    label="Select Location"
                    name={`locationId`}
                  >
                    <MenuItem value="" name="Location Not Selected" selected>
                      Location Not Selected
                    </MenuItem>
                    {locations.docs.map((doc) => (
                      <MenuItem
                        key={doc.id}
                        value={doc.id}
                        name={doc.data().name}
                      >
                        {doc.data().name}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>

                <Grid item xs={6} md={3}>
                  <DatePicker
                    fullWidth
                    name="dateBegin"
                    label="Begin"
                    value={dateBegin}
                    onChange={(newValue) => setDateBegin(newValue)}
                  ></DatePicker>
                </Grid>
                <Grid item xs={6} md={3}>
                  <DatePicker
                    fullWidth
                    name="dateEnd"
                    label="End"
                    value={dateEnd}
                    onChange={(newValue) => setDateEnd(newValue)}
                  ></DatePicker>
                </Grid>
                <Grid item xs={6} md={2}>
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting}
                    startIcon={
                      isSubmitting ? (
                        <CircularProgress size="0.9rem" />
                      ) : (
                        <RefreshIcon />
                      )
                    }
                  >
                    {isSubmitting ? "Refreshing" : "Refresh"}
                  </Button>
                </Grid>
              </Grid>
            )}
          </Form>
        )}
      </Formik>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={valueTab}
            onChange={handleChangeTab}
            aria-label="tabs"
            centered
            variant="fullWidth"
          >
            <Tab label="Products" {...a11yProps(0)} />
            <Tab label="Macros" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={valueTab} index={0}>
          {productCapacities && (
            <DataGrid
              loading={tableLoading}
              autoHeight
              density="compact"
              slots={{ toolbar: GridToolbar }}
              columns={productColumns}
              rows={productCapacities}
              getRowId={(p) => p.id}
              slotProps={{
                toolbar: {
                  csvOptions: { allColumns: true },
                  printOptions: { allColumns: true },
                },
              }}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    beginDate: false,
                    reportingDate: false,
                  },
                },
              }}
            />
          )}
        </TabPanel>
        <TabPanel value={valueTab} index={1}>
          {macroCapacities && (
            <DataGrid
              loading={tableLoading}
              autoHeight
              density="compact"
              slots={{ toolbar: GridToolbar }}
              columns={macroColumns}
              rows={macroCapacities}
              getRowId={(truck) => truck.id}
              slotProps={{
                toolbar: {
                  csvOptions: { allColumns: true },
                  printOptions: { allColumns: true },
                },
              }}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    beginDate: false,
                    reportingDate: false,
                  },
                },
              }}
            />
          )}
        </TabPanel>
      </Box>
    </Stack>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 1 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}
