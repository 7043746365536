import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { useCollectionOnce } from "react-firebase-hooks/firestore";
import {
  collection,
  query,
  where,
  orderBy,
  serverTimestamp,
} from "firebase/firestore";
import { db, auth } from "../../services/firebase";
import TransactionDataService from "../../services/transactions.service";
import { v4 as uuidv4 } from "uuid";

import { Formik, Form, Field, ErrorMessage } from "formik";
import { TextField } from "formik-mui";
import { array, number, object, string, bool, when, test } from "yup";
import { CustomizedSelect } from "../../common/select";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import BackspaceTwoToneIcon from "@mui/icons-material/BackspaceTwoTone";
import SaveIcon from "@mui/icons-material/Save";
import Typography from "@mui/material/Typography";

import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import Skeleton from "@mui/material/Skeleton";

import { enqueueSnackbar } from "notistack";

import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";

export const SurveySales = () => {
  const [reportingDate, setReportingDate] = useState(dayjs());
  const [outside, setOutside] = useState(false);
  const formikRef = useRef();
  const navigate = useNavigate();

  const allowTransfers = false;

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setFieldValue("outside", outside);
    }
  }, [outside]);

  const [locations, loadingLocations, errorLocations] = useCollectionOnce(
    query(
      collection(db, "locations"),
      where("active", "==", true),
      orderBy("name")
    )
  );

  const [products, loadingProducts, errorProducts] = useCollectionOnce(
    query(
      collection(db, "products"),
      where("active", "==", true),
      orderBy("name")
    )
  );

  const handleOutsideChange = (event, newOutside) => {
    setOutside(newOutside);
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        product: "",
        gallons: 0,
        location: "",
        outside: false,
        outsideLocation: "",
      }}
      validationSchema={object({
        product: string().required("Please select a product"),
        gallons: number()
          .integer("Please enter gallons in whole numbers"),
        location: string().required("Please select a location"),
        outside: bool(),
        outsideLocation: string().when("outside", {
          is: true,
          then: () => string().required("Please select a delivery location"),
        }),
      })}
      //onChange={e => console.log(e)}
      onSubmit={async (values, helpers) => {
        //console.log("sumbitted", values);
        const entryDate = reportingDate.startOf("day").toDate();
        const lastUpdated = serverTimestamp();

        if (values.outside) {
          const uuid = uuidv4();

          await TransactionDataService.create({
            reportingDate: entryDate,
            type: "sale",
            locationId: values.outsideLocation,
            transfer: uuid,
            productId: values.product,
            gallons: values.gallons,
            lastUpdatedBy: auth.currentUser.uid,
          });

          await TransactionDataService.create({
            reportingDate: entryDate,
            type: "out",
            locationId: values.location,
            transfer: uuid,
            productId: values.product,
            gallons: values.gallons,
            lastUpdatedBy: auth.currentUser.uid,
          });

          await TransactionDataService.create({
            reportingDate: entryDate,
            type: "in",
            locationId: values.outsideLocation,
            transfer: uuid,
            productId: values.product,
            gallons: values.gallons,
            lastUpdated: lastUpdated,
            lastUpdatedBy: auth.currentUser.uid,
          });

          /*
          const docRef = await addDoc(
            collection(db, "inventory-transactions"),
            {
              reportingDate: Timestamp.fromDate(entryDate),
              type: "sale",
              locationId: values.outsideLocation,
              transfer: true,
              productId: values.product,
              gallons: values.gallons,
              lastUpdated: lastUpdated,
              lastUpdatedBy: auth.currentUser.uid,
            }
          );

          await addDoc(collection(db, "inventory-transactions"), {
            reportingDate: Timestamp.fromDate(entryDate),
            type: "out",
            locationId: values.location,
            productId: values.product,
            gallons: values.gallons,
            sale: docRef.id,
            lastUpdated: lastUpdated,
            lastUpdatedBy: auth.currentUser.uid,
          });

          await addDoc(collection(db, "inventory-transactions"), {
            reportingDate: Timestamp.fromDate(entryDate),
            type: "in",
            locationId: values.outsideLocation,
            productId: values.product,
            gallons: values.gallons,
            sale: docRef.id,
            lastUpdated: lastUpdated,
            lastUpdatedBy: auth.currentUser.uid,
          });
          */
        } else {
          const data = {
            reportingDate: entryDate,
            type: "sale",
            locationId: values.location,
            productId: values.product,
            gallons: values.gallons,
            lastUpdated: lastUpdated,
            lastUpdatedBy: auth.currentUser.uid,
          };

          await TransactionDataService.create(data);

          /*
          await addDoc(collection(db, "inventory-transactions"), {
            reportingDate: Timestamp.fromDate(entryDate),
            type: "sale",
            locationId: values.location,
            transfer: false,
            productId: values.product,
            gallons: values.gallons,
            lastUpdated: lastUpdated,
            lastUpdatedBy: auth.currentUser.uid,
          });
          */
        }
        helpers.resetForm();
        enqueueSnackbar("Sales Transactions Recorded");
        navigate("/survey");
      }}
    >
      {({ values, errors, isSubmitting, handleChange }) => (
        <Form>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            spacing={1}
          >
            <Box display="flex" justifyContent="space-between" p={2}>
              <Typography variant="h5">Sales Reporting</Typography>

              <Box display="flex">
                <Button
                  variant="text"
                  onClick={() => navigate("/survey")}
                  startIcon={<BackspaceTwoToneIcon />}
                >
                  Back
                </Button>
              </Box>
            </Box>
            {errorProducts && (
              <Grid item xs={12}>
                <strong>Error: {JSON.stringify(errorProducts)}</strong>
              </Grid>
            )}
            {loadingProducts && (
              <Grid item xs={12}>
                <Skeleton />
              </Grid>
            )}
            {products && (
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={12}
                  md={4}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography variant="h6">
                    What product did you sell today?
                  </Typography>
                </Grid>
                <Grid item xs={6} md>
                  <Field
                    fullWidth
                    component={CustomizedSelect}
                    products={products}
                    label="Select Product"
                    name={`product`}
                    connected={`gallons`}
                    resetvalue={0}
                  >
                    <MenuItem value="" name="No Product" selected>
                      No Product
                    </MenuItem>
                    {products.docs.map((doc) => (
                      <MenuItem
                        key={doc.id}
                        value={doc.id}
                        name={doc.data().name}
                      >
                        {doc.data().name}
                      </MenuItem>
                    ))}
                  </Field>
                  <Box display="flex" justifyContent="left">
                    <ErrorMessage
                      name="product"
                      component="div"
                      className="cehm-error-sm"
                    ></ErrorMessage>
                  </Box>
                </Grid>

                <Grid item xs={6} md>
                  <Field
                    fullWidth
                    name={`gallons`}
                    component={TextField}
                    label="Gallons"
                    type="number"
                    variant="outlined"
                  ></Field>
                </Grid>
              </Grid>
            )}
            {errorLocations && (
              <Grid item xs={12}>
                <strong>Error: {JSON.stringify(errorLocations)}</strong>
              </Grid>
            )}
            {loadingLocations && (
              <Grid item xs={12}>
                <Skeleton />
              </Grid>
            )}
            {locations && (
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={12}
                  md={4}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography variant="h6">
                    Where did you load your truck?
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Field
                    fullWidth
                    component={CustomizedSelect}
                    label="Select Location"
                    name={`location`}
                  >
                    <MenuItem value="" name="Location Not Selected" selected>
                      Location Not Selected
                    </MenuItem>
                    {locations.docs.map((doc) => (
                      <MenuItem
                        key={doc.id}
                        value={doc.id}
                        name={doc.data().name}
                      >
                        {doc.data().name}
                      </MenuItem>
                    ))}
                  </Field>
                  <Box display="flex" justifyContent="left">
                    <ErrorMessage
                      name="location"
                      component="div"
                      className="cehm-error-sm"
                    ></ErrorMessage>
                  </Box>
                </Grid>
              </Grid>
            )}
            {allowTransfers && (
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={12}
                  md={6}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography variant="h6">
                    Did you deliver to customers outside your territory?
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <ToggleButtonGroup
                    color="primary"
                    exclusive
                    value={outside}
                    name={`outside`}
                    onChange={handleOutsideChange}
                  >
                    <ToggleButton value={true}>Yes</ToggleButton>
                    <ToggleButton value={false}>No</ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
            )}
            {outside && (
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={12}
                  md={4}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography variant="h6">
                    Where did you sell these gallons?
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Field
                    fullWidth
                    component={CustomizedSelect}
                    label="Select Location"
                    name={`outsideLocation`}
                  >
                    <MenuItem value="" name="Location Not Selected" selected>
                      Location Not Selected
                    </MenuItem>
                    {locations.docs.map((doc) => (
                      <MenuItem
                        key={doc.id}
                        value={doc.id}
                        name={doc.data().name}
                      >
                        {doc.data().name}
                      </MenuItem>
                    ))}
                  </Field>
                  <Box display="flex" justifyContent="left">
                    <ErrorMessage
                      name="outsideLocation"
                      component="div"
                      className="cehm-error-sm"
                    ></ErrorMessage>
                  </Box>
                </Grid>
              </Grid>
            )}
            <Box display="flex" justifyContent="right" p={2}>
              <Box display="flex" pr={2}>
                <DatePicker
                  name="reportingDate"
                  label="Reporting Date"
                  value={reportingDate}
                  onChange={(newValue) => setReportingDate(newValue)}
                ></DatePicker>
              </Box>
              <Box display="flex">
                <Button
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                  startIcon={
                    isSubmitting ? (
                      <CircularProgress size="0.9rem" />
                    ) : (
                      <SaveIcon />
                    )
                  }
                >
                  {isSubmitting ? "Submitting" : "Submit"}
                </Button>
              </Box>
            </Box>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
