import { createContext, useContext, useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { doc, onSnapshot } from "firebase/firestore";
import { auth, db } from "./firebase";

//https://github.com/fireclint/firebase-auth-context/blob/main/src/context/AuthContext.js

const UserContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [profile, setProfile] = useState({});

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, (currentUser) => {
      //console.log(currentUser);
      setUser(currentUser);
    });
    return () => {
      unsubscribeAuth();
    };
  }, []);

  useEffect(() => {
    if (!user || !user?.uid) {
      setProfile({});
      return;
    }

    const unsubscribe = onSnapshot(doc(db, "users", user?.uid), (doc) => {
      setProfile(doc.data());
    });

    return () => unsubscribe();
  }, [user]);

  return (
    <UserContext.Provider value={{ user, profile }}>
      {children}
    </UserContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(UserContext);
};
