import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { useCollectionOnce } from "react-firebase-hooks/firestore";
import { collection, query, where, orderBy } from "firebase/firestore";
import { db, fetchDocDictionary } from "../../services/firebase";
import InventoryDataService from "../../services/inventories.service";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import BackspaceTwoToneIcon from "@mui/icons-material/BackspaceTwoTone";
import RefreshIcon from "@mui/icons-material/Refresh";
import Typography from "@mui/material/Typography";

import { Formik, Form, Field } from "formik";
import { CustomizedSelect } from "../../common/select";

import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import Skeleton from "@mui/material/Skeleton";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";

import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import moment from "moment";

export const ReportActivities = () => {
  const [dateBegin, setDateBegin] = useState(dayjs());
  const [dateEnd, setDateEnd] = useState(dayjs());
  const formikRef = useRef();
  const navigate = useNavigate();

  const [tableLoading, setTableLoading] = useState(false);
  const [activities, setActivities] = useState([]);

  const [locations, locationsLoading, locationsError] = useCollectionOnce(
    query(
      collection(db, "locations"),
      where("active", "==", true),
      orderBy("name")
    )
  );

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setFieldValue("dateBegin", dateBegin);
    }
  }, [dateBegin]);

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setFieldValue("dateEnd", dateEnd);
    }
  }, [dateEnd]);

  const activityColumns = [
    {
      field: "location",
      headerName: "Location",
      flex: 1.5,
      hidden: false,
      editable: false,
    },
    {
      field: "reportingDate",
      headerName: "Reporting Date",
      flex: 1,
      type: "dateTime",
      editable: false,
      valueGetter: ({ value }) => value && value.toDate(),
      renderCell: (params) =>
        moment(params.row.reportingDate.toDate()).format("dddd, M/D/YY"),
    },
    {
      field: "inventories",
      headerName: "Inventories",
      flex: 1,
      type: "boolean",
      editable: false,
    },
    {
      field: "sales",
      headerName: "Sales",
      flex: 1,
      type: "boolean",
      editable: false,
    },
    {
      field: "transfers",
      headerName: "Transfers",
      flex: 1,
      type: "boolean",
      editable: false,
    },
    {
      field: "purchases",
      headerName: "Purchases",
      flex: 1,
      type: "boolean",
      editable: false,
    },
  ];

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="stretch"
      spacing={1}
    >
      <Box display="flex" justifyContent="space-between" p={2}>
        <Typography variant="h5">Activity Reported</Typography>

        <Box display="flex">
          <Button
            variant="text"
            onClick={() => navigate("/dashboard")}
            startIcon={<BackspaceTwoToneIcon />}
          >
            Back
          </Button>
        </Box>
      </Box>
      <Formik
        innerRef={formikRef}
        initialValues={{ locationId: "", dateBegin: dayjs(), dateEnd: dayjs() }}
        onSubmit={async (values, helpers) => {
          setTableLoading(true);

          const activities = [];

          const response = await InventoryDataService.getActivityForLocation(
            values.locationId,
            dateBegin.startOf("day").toDate(),
            dateEnd.startOf("day").toDate()
          );

          activities.push(...response.data);

          const a = {};
          const ids = [];

          if (values.locationId) {
            ids.push(values.locationId);
          } else {
            locations.docs.forEach((doc) => ids.push(doc.id));
          }

          ids.forEach((id) => {
            const location = locations.docs.find((l) => l.id === id);

            let loop = new Date(dateBegin.startOf("day").toDate());
            while (loop <= dateEnd.startOf("day").toDate()) {
              let dateKey = dayjs(loop).format("YYYYMMDD");
              let key = `${location.id}-${dateKey}`;

              a[key] = {
                id: key,
                location: location?.data()?.name,
                reportingDate: dayjs(loop),
                inventories: false,
                sales: false,
                transfers: false,
                purchases: false,
              };

              let newDate = loop.setDate(loop.getDate() + 1);
              loop = new Date(newDate);
            }
          });

          activities.forEach((activity) => {
            let dateKey = dayjs(activity.reportingDate).format("YYYYMMDD");
            let key = `${activity.locationId}-${dateKey}`;

            if (a[key]) {
              a[key] = {
                id: key,
                location: a[key].location,
                reportingDate: a[key].reportingDate,
                inventories: a[key].inventories || activity.inventory > 0,
                sales: a[key].sales || activity.sale > 0,
                transfers: a[key].transfers || activity.in > 0 || activity.out > 0,
                purchases: a[key].purchases || activity.purchase > 0,
              };
            }
          });

          console.log("activities", a);

          setActivities(
            Object.values(a).map((element) => ({
              ...element,
            }))
          );

          //const done = await new Promise((res) => setTimeout(res, 2500));
          setTableLoading(false);
        }}
      >
        {({ values, errors, isSubmitting }) => (
          <Form>
            {locationsError && (
              <Grid item xs={12}>
                <strong>Error: {JSON.stringify(locationsError)}</strong>
              </Grid>
            )}
            {locationsLoading && (
              <Grid item xs={12}>
                <Skeleton />
              </Grid>
            )}
            {locations && (
              <Grid container spacing={1}>
                <Grid item xs={12} md={4}>
                  <Field
                    fullWidth
                    component={CustomizedSelect}
                    label="Select Location"
                    name={`locationId`}
                  >
                    <MenuItem value="" name="Location Not Selected" selected>
                      Location Not Selected
                    </MenuItem>
                    {locations.docs.map((doc) => (
                      <MenuItem
                        key={doc.id}
                        value={doc.id}
                        name={doc.data().name}
                      >
                        {doc.data().name}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>

                <Grid item xs={6} md={3}>
                  <DatePicker
                    fullWidth
                    name="dateBegin"
                    label="Begin"
                    value={dateBegin}
                    onChange={(newValue) => setDateBegin(newValue)}
                  ></DatePicker>
                </Grid>
                <Grid item xs={6} md={3}>
                  <DatePicker
                    fullWidth
                    name="dateEnd"
                    label="End"
                    value={dateEnd}
                    onChange={(newValue) => setDateEnd(newValue)}
                  ></DatePicker>
                </Grid>
                <Grid item xs={6} md={2}>
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting}
                    startIcon={
                      isSubmitting ? (
                        <CircularProgress size="0.9rem" />
                      ) : (
                        <RefreshIcon />
                      )
                    }
                  >
                    {isSubmitting ? "Refreshing" : "Refresh"}
                  </Button>
                </Grid>
              </Grid>
            )}
          </Form>
        )}
      </Formik>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <DataGrid
            loading={tableLoading}
            autoHeight
            density="compact"
            slots={{ toolbar: GridToolbar }}
            columns={activityColumns}
            rows={activities}
            getRowId={(p) => p.id}
            slotProps={{
              toolbar: {
                csvOptions: { allColumns: true },
                printOptions: { allColumns: true },
              },
            }}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  reportingDate: true,
                },
              },
            }}
            getCellClassName={(params) => {
                if (params.field === 'location' || params.field === 'reportingDate' || params.value == null) {
                  return '';
                }
                return params.value ? 'data-grid-true' : 'data-grid-false';
              }}
          />
        </Box>
      </Box>
    </Stack>
  );
};
