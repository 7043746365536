import http from "./http-common";

class InventoryDataService {
  getAll() {
    return http.get("/inventories");
  }

  getForLocation(locationId, begin, end) {
    return http.get(`/inventories?begin=${begin}&end=${end}&locationId=${locationId}`);
  }

  getForDates(begin, end) {
    return http.get(`/inventories?begin=${begin}&end=${end}`);
  }

  getShrinkForLocation(locationId, begin, end) {
    return http.get(`/inventories/shrink?begin=${begin}&end=${end}&locationId=${locationId}`);
  }

  getShrinkForDates(locationId, begin, end) {
    return http.get(`/inventories/shrink?begin=${begin}&end=${end}`);
  }

  getActivityForLocation(locationId, begin, end) {
    return http.get(`/inventories/activity?begin=${begin}&end=${end}&locationId=${locationId}`);
  }

  getActivityForDates(locationId, begin, end) {
    return http.get(`/inventories/activity?begin=${begin}&end=${end}`);
  }

  get(id) {
    return http.get(`/inventories/${id}`);
  }

  create(data) {
    return http.post("/inventories", data);
  }

  delete(id) {
    return http.delete(`/inventories/${id}`);
  }
}

export default new InventoryDataService();
