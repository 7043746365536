import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { logout } from "../services/firebase";
import { UserAuth } from "../services/AuthContext";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import Divider from "@mui/material/Divider";

const pages = [
  { label: "Driver Reporting", nav: "/survey" },
  { label: "Analytics", nav: "/dashboard" },
  { label: "Maintenance", nav: "/maintenance" },
];
const settings = [{ label: "Logout", nav: "/logout" }];

export const TopBar = () => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const { user, profile } = UserAuth();
  //const [user, loading, error] = useAuthState(auth);
  //const [name, setName] = useState("");
  //const [role, setRole] = useState("");
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (loading) return;
  //   if (!user) return navigate("/");

  //   const unsubscribe = onSnapshot(doc(db, "users", user?.uid), (doc) => {
  //     setName(doc.data().name);
  //     setRole(doc.data().role);
  //   });

  //   return () => unsubscribe();
  // }, [user, loading]);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (nav) => {
    setAnchorElNav(null);
    if (nav !== "") {
      navigate(nav);
    }
  };

  const handleCloseUserMenu = (nav) => {
    setAnchorElUser(null);
    if (nav === "/logout") {
      //setName("");
      logout();
    }
  };

  return (
    <AppBar position="static" className="westco-red">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              disableScrollLock
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={() => handleCloseNavMenu("")}
              sx={{
                display: "block",
              }}
            >
              {pages.map((page) => (
                <MenuItem
                  key={page.nav}
                  onClick={() => handleCloseNavMenu(page.nav)}
                >
                  <Typography textAlign="center">{page.label}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            sx={{
              mr: 2,
              display: "flex",
              flexGrow: 1,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            WESTCO Fuel
          </Typography>

          {user && (
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Profile settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar {...stringAvatar(profile?.name)} />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                disableScrollLock
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={() => handleCloseUserMenu("")}
              >
                <MenuItem key="name">
                  <Typography textAlign="left">{profile?.name}</Typography>
                </MenuItem>
                <MenuItem key="email">
                  <Typography textAlign="left">{user?.email}</Typography>
                </MenuItem>
                <Divider />
                {settings.map((setting) => (
                  <MenuItem
                    key={setting.nav}
                    onClick={() => handleCloseUserMenu(setting.nav)}
                  >
                    <Typography textAlign="center">{setting.label}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

function stringToColor(string) {
  if (string === undefined) return "#c40f33";

  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  if (!name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: ``,
    };
  } else if (!name.includes(" ")) {
    return {
      sx: {
        bgcolor: "#b5949b",
      },
      children: `${name[0]}`,
    };
  } else {
    return {
      sx: {
        bgcolor: "#b5949b",
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }
}
